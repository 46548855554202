
  function buildFormatLongFn(args) {
    return function () {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      // TODO: Remove String()
      var width = options.width ? String(options.width) : args.defaultWidth;
      var format = args.formats[width] || args.formats[args.defaultWidth];
      return format;
    };
  }

  function buildLocalizeFn(args) {
    return function (dirtyIndex, options) {
      var context = options !== null && options !== void 0 && options.context ? String(options.context) : 'standalone';
      var valuesArray;

      if (context === 'formatting' && args.formattingValues) {
        var defaultWidth = args.defaultFormattingWidth || args.defaultWidth;
        var width = options !== null && options !== void 0 && options.width ? String(options.width) : defaultWidth;
        valuesArray = args.formattingValues[width] || args.formattingValues[defaultWidth];
      } else {
        var _defaultWidth = args.defaultWidth;

        var _width = options !== null && options !== void 0 && options.width ? String(options.width) : args.defaultWidth;

        valuesArray = args.values[_width] || args.values[_defaultWidth];
      }

      var index = args.argumentCallback ? args.argumentCallback(dirtyIndex) : dirtyIndex; // @ts-ignore: For some reason TypeScript just don't want to match it, no matter how hard we try. I challenge you to try to remove it!

      return valuesArray[index];
    };
  }

  function buildMatchFn(args) {
    return function (string) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var width = options.width;
      var matchPattern = width && args.matchPatterns[width] || args.matchPatterns[args.defaultMatchWidth];
      var matchResult = string.match(matchPattern);

      if (!matchResult) {
        return null;
      }

      var matchedString = matchResult[0];
      var parsePatterns = width && args.parsePatterns[width] || args.parsePatterns[args.defaultParseWidth];
      var key = Array.isArray(parsePatterns) ? findIndex(parsePatterns, function (pattern) {
        return pattern.test(matchedString);
      }) : findKey(parsePatterns, function (pattern) {
        return pattern.test(matchedString);
      });
      var value;
      value = args.valueCallback ? args.valueCallback(key) : key;
      value = options.valueCallback ? options.valueCallback(value) : value;
      var rest = string.slice(matchedString.length);
      return {
        value: value,
        rest: rest
      };
    };
  }

  function findKey(object, predicate) {
    for (var key in object) {
      if (object.hasOwnProperty(key) && predicate(object[key])) {
        return key;
      }
    }

    return undefined;
  }

  function findIndex(array, predicate) {
    for (var key = 0; key < array.length; key++) {
      if (predicate(array[key])) {
        return key;
      }
    }

    return undefined;
  }

  function buildMatchPatternFn(args) {
    return function (string) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var matchResult = string.match(args.matchPattern);
      if (!matchResult) return null;
      var matchedString = matchResult[0];
      var parseResult = string.match(args.parsePattern);
      if (!parseResult) return null;
      var value = args.valueCallback ? args.valueCallback(parseResult[0]) : parseResult[0];
      value = options.valueCallback ? options.valueCallback(value) : value;
      var rest = string.slice(matchedString.length);
      return {
        value: value,
        rest: rest
      };
    };
  }

  var formatDistanceLocale = {
    lessThanXSeconds: {
      one: 'mindre enn ett sekund',
      other: 'mindre enn {{count}} sekunder'
    },
    xSeconds: {
      one: 'ett sekund',
      other: '{{count}} sekunder'
    },
    halfAMinute: 'et halvt minutt',
    lessThanXMinutes: {
      one: 'mindre enn ett minutt',
      other: 'mindre enn {{count}} minutter'
    },
    xMinutes: {
      one: 'ett minutt',
      other: '{{count}} minutter'
    },
    aboutXHours: {
      one: 'omtrent en time',
      other: 'omtrent {{count}} timer'
    },
    xHours: {
      one: 'en time',
      other: '{{count}} timer'
    },
    xDays: {
      one: 'en dag',
      other: '{{count}} dager'
    },
    aboutXWeeks: {
      one: 'omtrent en uke',
      other: 'omtrent {{count}} uker'
    },
    xWeeks: {
      one: 'en uke',
      other: '{{count}} uker'
    },
    aboutXMonths: {
      one: 'omtrent en måned',
      other: 'omtrent {{count}} måneder'
    },
    xMonths: {
      one: 'en måned',
      other: '{{count}} måneder'
    },
    aboutXYears: {
      one: 'omtrent ett år',
      other: 'omtrent {{count}} år'
    },
    xYears: {
      one: 'ett år',
      other: '{{count}} år'
    },
    overXYears: {
      one: 'over ett år',
      other: 'over {{count}} år'
    },
    almostXYears: {
      one: 'nesten ett år',
      other: 'nesten {{count}} år'
    }
  };

  var formatDistance = function (token, count, options) {
    var result;
    var tokenValue = formatDistanceLocale[token];

    if (typeof tokenValue === 'string') {
      result = tokenValue;
    } else if (count === 1) {
      result = tokenValue.one;
    } else {
      result = tokenValue.other.replace('{{count}}', String(count));
    }

    if (options !== null && options !== void 0 && options.addSuffix) {
      if (options.comparison && options.comparison > 0) {
        return 'om ' + result;
      } else {
        return result + ' siden';
      }
    }

    return result;
  };

  var formatDistance$1 = formatDistance;

  var dateFormats = {
    full: 'EEEE d. MMMM y',
    long: 'd. MMMM y',
    medium: 'd. MMM y',
    short: 'dd.MM.y'
  };
  var timeFormats = {
    full: "'kl'. HH:mm:ss zzzz",
    long: 'HH:mm:ss z',
    medium: 'HH:mm:ss',
    short: 'HH:mm'
  };
  var dateTimeFormats = {
    full: "{{date}} 'kl.' {{time}}",
    long: "{{date}} 'kl.' {{time}}",
    medium: '{{date}} {{time}}',
    short: '{{date}} {{time}}'
  };
  var formatLong = {
    date: buildFormatLongFn({
      formats: dateFormats,
      defaultWidth: 'full'
    }),
    time: buildFormatLongFn({
      formats: timeFormats,
      defaultWidth: 'full'
    }),
    dateTime: buildFormatLongFn({
      formats: dateTimeFormats,
      defaultWidth: 'full'
    })
  };
  var formatLong$1 = formatLong;

  var formatRelativeLocale = {
    lastWeek: "'forrige' eeee 'kl.' p",
    yesterday: "'i går kl.' p",
    today: "'i dag kl.' p",
    tomorrow: "'i morgen kl.' p",
    nextWeek: "EEEE 'kl.' p",
    other: 'P'
  };

  var formatRelative = function (token, _date, _baseDate, _options) {
    return formatRelativeLocale[token];
  };

  var formatRelative$1 = formatRelative;

  var eraValues = {
    narrow: ['f.Kr.', 'e.Kr.'],
    abbreviated: ['f.Kr.', 'e.Kr.'],
    wide: ['før Kristus', 'etter Kristus']
  };
  var quarterValues = {
    narrow: ['1', '2', '3', '4'],
    abbreviated: ['Q1', 'Q2', 'Q3', 'Q4'],
    wide: ['1. kvartal', '2. kvartal', '3. kvartal', '4. kvartal']
  };
  var monthValues = {
    narrow: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
    abbreviated: ['jan.', 'feb.', 'mars', 'apr.', 'mai', 'juni', 'juli', 'aug.', 'sep.', 'okt.', 'nov.', 'des.'],
    wide: ['januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember']
  };
  var dayValues = {
    narrow: ['S', 'M', 'T', 'O', 'T', 'F', 'L'],
    short: ['sø', 'ma', 'ti', 'on', 'to', 'fr', 'lø'],
    abbreviated: ['søn', 'man', 'tir', 'ons', 'tor', 'fre', 'lør'],
    wide: ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag']
  };
  var dayPeriodValues = {
    narrow: {
      am: 'a',
      pm: 'p',
      midnight: 'midnatt',
      noon: 'middag',
      morning: 'på morg.',
      afternoon: 'på etterm.',
      evening: 'på kvelden',
      night: 'på natten'
    },
    abbreviated: {
      am: 'a.m.',
      pm: 'p.m.',
      midnight: 'midnatt',
      noon: 'middag',
      morning: 'på morg.',
      afternoon: 'på etterm.',
      evening: 'på kvelden',
      night: 'på natten'
    },
    wide: {
      am: 'a.m.',
      pm: 'p.m.',
      midnight: 'midnatt',
      noon: 'middag',
      morning: 'på morgenen',
      afternoon: 'på ettermiddagen',
      evening: 'på kvelden',
      night: 'på natten'
    }
  };

  var ordinalNumber = function (dirtyNumber, _options) {
    var number = Number(dirtyNumber);
    return number + '.';
  };

  var localize = {
    ordinalNumber: ordinalNumber,
    era: buildLocalizeFn({
      values: eraValues,
      defaultWidth: 'wide'
    }),
    quarter: buildLocalizeFn({
      values: quarterValues,
      defaultWidth: 'wide',
      argumentCallback: function (quarter) {
        return quarter - 1;
      }
    }),
    month: buildLocalizeFn({
      values: monthValues,
      defaultWidth: 'wide'
    }),
    day: buildLocalizeFn({
      values: dayValues,
      defaultWidth: 'wide'
    }),
    dayPeriod: buildLocalizeFn({
      values: dayPeriodValues,
      defaultWidth: 'wide'
    })
  };
  var localize$1 = localize;

  var matchOrdinalNumberPattern = /^(\d+)\.?/i;
  var parseOrdinalNumberPattern = /\d+/i;
  var matchEraPatterns = {
    narrow: /^(f\.? ?Kr\.?|fvt\.?|e\.? ?Kr\.?|evt\.?)/i,
    abbreviated: /^(f\.? ?Kr\.?|fvt\.?|e\.? ?Kr\.?|evt\.?)/i,
    wide: /^(før Kristus|før vår tid|etter Kristus|vår tid)/i
  };
  var parseEraPatterns = {
    any: [/^f/i, /^e/i]
  };
  var matchQuarterPatterns = {
    narrow: /^[1234]/i,
    abbreviated: /^q[1234]/i,
    wide: /^[1234](\.)? kvartal/i
  };
  var parseQuarterPatterns = {
    any: [/1/i, /2/i, /3/i, /4/i]
  };
  var matchMonthPatterns = {
    narrow: /^[jfmasond]/i,
    abbreviated: /^(jan|feb|mars?|apr|mai|juni?|juli?|aug|sep|okt|nov|des)\.?/i,
    wide: /^(januar|februar|mars|april|mai|juni|juli|august|september|oktober|november|desember)/i
  };
  var parseMonthPatterns = {
    narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
    any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^mai/i, /^jun/i, /^jul/i, /^aug/i, /^s/i, /^o/i, /^n/i, /^d/i]
  };
  var matchDayPatterns = {
    narrow: /^[smtofl]/i,
    short: /^(sø|ma|ti|on|to|fr|lø)/i,
    abbreviated: /^(søn|man|tir|ons|tor|fre|lør)/i,
    wide: /^(søndag|mandag|tirsdag|onsdag|torsdag|fredag|lørdag)/i
  };
  var parseDayPatterns = {
    any: [/^s/i, /^m/i, /^ti/i, /^o/i, /^to/i, /^f/i, /^l/i]
  };
  var matchDayPeriodPatterns = {
    narrow: /^(midnatt|middag|(på) (morgenen|ettermiddagen|kvelden|natten)|[ap])/i,
    any: /^([ap]\.?\s?m\.?|midnatt|middag|(på) (morgenen|ettermiddagen|kvelden|natten))/i
  };
  var parseDayPeriodPatterns = {
    any: {
      am: /^a(\.?\s?m\.?)?$/i,
      pm: /^p(\.?\s?m\.?)?$/i,
      midnight: /^midn/i,
      noon: /^midd/i,
      morning: /morgen/i,
      afternoon: /ettermiddag/i,
      evening: /kveld/i,
      night: /natt/i
    }
  };
  var match = {
    ordinalNumber: buildMatchPatternFn({
      matchPattern: matchOrdinalNumberPattern,
      parsePattern: parseOrdinalNumberPattern,
      valueCallback: function (value) {
        return parseInt(value, 10);
      }
    }),
    era: buildMatchFn({
      matchPatterns: matchEraPatterns,
      defaultMatchWidth: 'wide',
      parsePatterns: parseEraPatterns,
      defaultParseWidth: 'any'
    }),
    quarter: buildMatchFn({
      matchPatterns: matchQuarterPatterns,
      defaultMatchWidth: 'wide',
      parsePatterns: parseQuarterPatterns,
      defaultParseWidth: 'any',
      valueCallback: function (index) {
        return index + 1;
      }
    }),
    month: buildMatchFn({
      matchPatterns: matchMonthPatterns,
      defaultMatchWidth: 'wide',
      parsePatterns: parseMonthPatterns,
      defaultParseWidth: 'any'
    }),
    day: buildMatchFn({
      matchPatterns: matchDayPatterns,
      defaultMatchWidth: 'wide',
      parsePatterns: parseDayPatterns,
      defaultParseWidth: 'any'
    }),
    dayPeriod: buildMatchFn({
      matchPatterns: matchDayPeriodPatterns,
      defaultMatchWidth: 'any',
      parsePatterns: parseDayPeriodPatterns,
      defaultParseWidth: 'any'
    })
  };
  var match$1 = match;

  /**
   * @type {Locale}
   * @category Locales
   * @summary Norwegian Bokmål locale.
   * @language Norwegian Bokmål
   * @iso-639-2 nob
   * @author Hans-Kristian Koren [@Hanse]{@link https://github.com/Hanse}
   * @author Mikolaj Grzyb [@mikolajgrzyb]{@link https://github.com/mikolajgrzyb}
   * @author Dag Stuan [@dagstuan]{@link https://github.com/dagstuan}
   */

  const nb = {
    code: 'nb',
    formatDistance: formatDistance$1,
    formatLong: formatLong$1,
    formatRelative: formatRelative$1,
    localize: localize$1,
    match: match$1,
    options: {
      weekStartsOn: 1
      /* Monday */
      ,
      firstWeekContainsDate: 4
    }
  };


export {nb}




 

